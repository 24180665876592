<template>
  <div>


    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/college/personnel_etc" large class="mr-10">
        <span class="bottom-navi">ข้อมูลบุคคลสถานศึกษา </span>
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>
      <v-btn
        v-if="
          sys_config_collegemanpowers.sys_config_CollegemanpowerHuman === '1'
        "
        to="/college/rate_workforce_g"
        large
        class="mr-7"
      >
        <span class="bottom-navi">ข้อมูลอัตราสถานศึกษา </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>
      <v-btn
        v-if="
          sys_config_collegemanpowers.sys_config_CollegemanpowerStudent ===
            '1' && rate_work_gCheck.rate_work_g_college_code
        "
        to="/college/rate_workforce"
        class="mr-15"
      >
        <span class="bottom-navi">ข้อมูลนักเรียนนักศึกษา </span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>
      <v-btn
        v-if="
          sys_config_collegemanpowers.sys_config_CollegemanpowerCalStD ===
            '1' && rate_work_gCheck.rate_work_g_college_code
        "
        to="/college/rate_workforce_pro"
        class="mr-14"
      >
        <span class="bottom-navi">ประมวลผลห้องเรียน </span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>
      <v-btn
        v-if="
          rate_work_college_cal_roomCheck.status !== false &&
            rate_work_gCheck.rate_work_g_college_code
        "
        to="/college/rate_workforce_cal"
        class="mr-15"
      >
        <span class="bottom-navi">ประมวลผลอัตรากำลัง </span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn>
      <v-btn
        v-if="rate_work_college_cal_roomCheck.status !== false"
        to="/college/rate_workforce_report"
        class="mr-7"
      >
        <span class="bottom-navi">รายงานผลอัตรากำลัง </span>
        <v-icon>mdi-numeric-5-box</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-container id="upgrade" fluid tag="section" class="text_google">
      <v-row>
        <v-col cols="12" md="12">
          <base-material-card color="primary">
            <template v-slot:heading>
              <h2 class="h1 font-weight-light text_google">
                <v-icon large left>mdi-file-send</v-icon
                >โปรแกรมคำนวณอัตรากำลังในสถานศึกษา
                สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา :
                {{ user.college_id_code_type_manpower }}
              </h2>
            </template>

            <v-row>
              <v-col cols="12" md="12">
                <v-card class="mx-auto pa-5">
                  <v-row class="mb-10" justify="center" no-gutters>
                    <v-col md="10">
                      <v-card>
                        <v-row class="ma-2">
                          <v-col cols="12" md="12">
                            <h3>
                              <v-select
                                filled
                                v-model="select"
                                :items="items"
                                item-value="state"
                                item-text="state"
                                @change="rate_work_g_search()"
                                label="ปีการศึกษา พ.ศ.  : "
                                :hint="
                                  `อัตรากำลังปี : ,${select.state}, ${select.abbr}`
                                "
                                persistent-hint
                                return-object
                                single-line
                              >
                              </v-select>
                            </h3>
                          </v-col>
                          <v-col cols="12" md="2" class="text-center"> </v-col>
                          <v-col cols="12" md="5" class="text-center">
                            <h3>รหัสสถานศึกษา :{{ user.user_name }}</h3>
                            <h3>ชื่อสถานศึกษา : {{ user.college_name }}</h3>
                          </v-col>
                          <v-col cols="12" md="5" class="text-center">
                            <h3>ประเภทสถานศึกษา : {{ user.name_ctm }}</h3>
                            <h3>
                              ปีการศึกษา :
                              {{ rate_work_g_query.rate_work_g_year }}
                            </h3>
                            <v-btn
                              v-if="
                                rate_work_g_query.rate_work_g_status ===
                                  'Confirm'
                              "
                              rounded
                              color="info"
                              :href="
                                '#/college/print_rate_workforce_cal/' +
                                  rate_work_g_query.rate_work_g_year +
                                  '/' +
                                  rate_work_g_query.college_code
                              "
                              target="_blank"
                            >
                              <v-icon>mdi-printer</v-icon>
                              พิมพ์รายงานข้อมูล</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      v-if="rate_work_g_query.rate_work_g_status !== 'Confirm'"
                    >
                      <h2 class="red--text text-center">
                        ท่านยังไม่ได้ยืนยันบันทึกรายการ ในขั้นตอนที่ 4
                        ทำให้ข้อมูลส่วนนี้จะไม่ปรากฎ
                      </h2>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      v-if="rate_work_g_query.rate_work_g_status === 'Confirm'"
                    >
                      <v-row class="mb-10" justify="center" no-gutters>
                        <v-col md="10">
                          <v-card class="pa-2" outlined elevation="2">
                            <v-form ref="rate_work_g_queryform" lazy-validation>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex md12>
                                    <v-row>
                                      <v-col cols="12" md="12">
                                        <v-simple-table>
                                          <thead>
                                            <tr>
                                              <th
                                                class="text-center"
                                                width="20%"
                                              >
                                                ประเภทอัตรา
                                              </th>
                                              <th
                                                class="text-center"
                                                width="15%"
                                              >
                                                มีจริง
                                              </th>
                                              <th
                                                class="text-center grey lighten-1"
                                                width="15%"
                                              >
                                                จำนวนห้องเรียน
                                              </th>
                                              <th
                                                class="text-center"
                                                width="15%"
                                              >
                                                เกณฑ์
                                              </th>
                                              <th
                                                class="text-center grey lighten-1"
                                                width="15%"
                                              >
                                                อัตรากำลังรวม
                                              </th>
                                              <th
                                                class="text-center"
                                                width="20%"
                                              >
                                                (-ขาด/+เกิน)
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>ผอ.สถานศึกษา</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_dr
                                                }}
                                              </td>
                                              <td
                                                class="text-center grey lighten-1"
                                                rowspan="2"
                                              >
                                                {{
                                                  rate_work_g_query.rate_room_cal_all
                                                }}
                                              </td>
                                              <td class="text-center ">
                                                {{
                                                  rate_work_g_query.rate_criterion_dr
                                                }}
                                              </td>
                                              <td
                                                class="text-center grey lighten-1"
                                                rowspan="2"
                                              >
                                                {{
                                                  rate_work_g_query.rate_man_power_teach_all
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_criterion_incomplete_dr
                                                }}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>รอง ผอ.สถานศึกษา</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sedr
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_criterion_sedr
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_criterion_incomplete_sedr
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                              >
                                                ครูผู้สอน (รวม)
                                              </td>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_teach_official_all
                                                }}
                                              </td>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_teach_civil_all
                                                }}
                                              </td>
                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_teach_all
                                                }}
                                              </td>
                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_teach_alls
                                                }}
                                              </td>
                                              <td
                                                class="text-center blue darken-1 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_incomplete_teach_all
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td></td>
                                              <td class="text-center">
                                                ข้าราชการ
                                              </td>
                                              <td class="text-center">
                                                พนักงานราชการ
                                              </td>
                                              <td class="text-center">
                                                ข้าราชการ
                                              </td>
                                              <td class="text-center">
                                                พนักงานราชการ
                                              </td>
                                              <td class="text-center"></td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาสามัญ</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาอุตสาหกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาพาณิชยกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาศิลปกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาคหกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาเกษตรกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาประมง</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                -ประเภทวิชาอุตสาหกรรมการท่องเที่ยว
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                -ประเภทวิชาอุตสาหกรรมสิ่งทอ
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                -ประเภทวิชาเทคโนโลยีสารสนเทศ
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_incomplete
                                                }}
                                              </td>
                                            </tr>

                                            <!--  -->
                                            <tr>
                                              <td>
                                                -ประเภทวิชาอุตสาหกรรมบันเทิงและดนตรี
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_incomplete
                                                }}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                -ประเภทวิชาพาณิชยนาวี
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_incomplete
                                                }}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                -ประเภทวิชาเทคโนโลยีและนวัตกรรมเกษตรอุตสาหกรรม
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_incomplete
                                                }}
                                              </td>
                                            </tr>

                                            <!--  -->
                                            <tr>
                                              <td>-หลักสูตรระยะสั้น*</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bold">
                                                <strong
                                                  >บุคลากรสนับสนุนการสอน</strong
                                                >
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                              >
                                                <h3>รวมอัตรา</h3>
                                              </td>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                                colspan="2"
                                              >
                                                <h3>
                                                  {{
                                                    rate_work_g_query.rate_man_power_all
                                                  }}
                                                </h3>
                                              </td>

                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_all
                                                }}
                                              </td>
                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              ></td>
                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_incomplete_all
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bold"></td>
                                              <td class="text-center">
                                                ลูกจ้างประจำ
                                              </td>
                                              <td class="text-center">
                                                ลูกจ้างชั่วคราว(ครูจ้างสอน)
                                              </td>
                                              <td class="text-center">
                                                ลูกจ้างชั่วคราว(สายสนับสนุน,เจ้าหน้าที่)
                                              </td>
                                              <td class="text-center"></td>
                                              <td class="text-center"></td>
                                            </tr>

                                            <tr>
                                              <td class="bold">
                                                <strong
                                                  >ลูกจ้างประจำ/ชั่วคราว</strong
                                                >
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_pte_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_pte_tc
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_pte_tb
                                                }}
                                              </td>
                                              <td class="text-center"></td>
                                              <td class="text-center"></td>
                                            </tr>
                                          </tbody>
                                        </v-simple-table>
                                      </v-col>
                                    </v-row>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-form>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-simple-table>
                            <thead>
                              <tr>
                                <td colspan="9" class="text-center">
                                  <h2>
                                    แสดงอัตรากำลังประกอบการตัดสินใจ
                                    ผู้บริหารสถานศึกษา
                                  </h2>
                                  <h1 class="red--text">
                                    อยู่ระหว่างทดสอบสถิติ
                                  </h1>
                                </td>
                                <td colspan="3" align="right">
                                  <v-btn
                                    rounded
                                    color="info"
                                    v-if="
                                      rate_work_g_query.rate_work_g_status ===
                                        'Confirm'
                                    "
                                    :href="
                                      '#/college/print_rate_workforce_cal2/' +
                                        rate_work_g_query.rate_work_g_year +
                                        '/' +
                                        rate_work_g_query.college_code
                                    "
                                    target="_blank"
                                  >
                                    <v-icon large dark>
                                      mdi-printer
                                    </v-icon>
                                    พิมพ์รายงานข้อมูล
                                  </v-btn>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  ลำดับ
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  ประเภท
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  หลักสูตร
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  สาขาวิชา
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  จำนวนนักเรียน
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  รวม/จำนวนนักเรียนประเภท
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  ร้อยละ
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  อัตรากำลังคำนวณ
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  อัตรากำลัง/สาขา
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  ข้าราชการ
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  ข้าราชการ(มีอยู่)
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  ข้าราชการ(ขาด)
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  พนง.
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  พนง.(มีอยู่)
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                พนง.(ขาด)
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  ครูจ้างสอน 
                                </td>
                                <td
                                  class="text-center green lighten-1 white--text"
                                >
                                  สรุปภาพรวม 
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item,
                                index) in rate_work_g_query_cal_idbranchs"
                                :key="item.index"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.type_course }}</td>
                                <td>{{ item.year_course }}</td>
                                <td>{{ item.branch_name_th }}</td>
                                <td>{{ item.sumStudent }}</td>
                                <td>{{ item.sumAllStd }}</td>
                                <td>
                                  {{
                                    parseFloat(
                                      (parseFloat(item.sumStudent) * 100) /
                                        item.sumAllStd
                                    ).toFixed(2)
                                  }}
                                </td>
                                <td >{{ parseFloat(item.manPower).toFixed(2) }}</td>
                                <td class="td-bg text-center">
                                  {{
                                    parseFloat(
                                      (((parseFloat(item.sumStudent) * 100) /
                                        item.sumAllStd) *
                                        item.manPower) /
                                        100
                                    ).toFixed(2)
                                  }}
                                </td>
                               
                                <td class="td-bg text-center">
                                  {{
                                    parseFloat(
                                      ((((parseFloat(item.sumStudent) * 100) /
                                        item.sumAllStd) *
                                        item.manPower) /
                                        100) *
                                        0.9
                                    ).toFixed(2)
                                  }}
                                </td>
                                <td class="td-bg text-center">
                                  {{ 
                                    item.countTeach
                                    }}
                                </td>
                                <td class="red--text text-center">
                                  {{ 
                                   Math.round(item.countTeach-(((((parseFloat(item.sumStudent) * 100) /
                                        item.sumAllStd) *
                                        item.manPower) /
                                        100) *
                                        0.9))
                                    }}
                                </td>
                                <td class="td-bg text-center">
                                  {{
                                    parseFloat(
                                      ((((parseFloat(item.sumStudent) * 100) /
                                        item.sumAllStd) *
                                        item.manPower) /
                                        100) *
                                        0.1
                                    ).toFixed(2)
                                  }}
                                </td>
                                <td class="td-bg text-center">  
                                  {{ 
                                    item.countTeachGov }}
                                </td>
                                <td class="red--text text-center">
                                  {{ 
                                   Math.round(item.countTeachGov-(((((parseFloat(item.sumStudent) * 100) /
                                        item.sumAllStd) *
                                        item.manPower) /
                                        100) *
                                        0.1))
                                    }}
                                </td>
                                <td class="td-bg text-center">  
                                  {{ 
                                    item.countTeachTem }}
                                </td>

                                <td class="td-bg-sum text-center">  
                                  {{ 
                                  Math.round(( parseFloat(item.countTeachTem)+parseFloat(item.countTeach)+parseFloat(item.countTeachGov))-((((parseFloat(item.sumStudent) * 100) /
                                        item.sumAllStd) *
                                        item.manPower) /
                                        100))                                    
                                    }}
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>

      <!-- V-model updaterate_work_collegedialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="updaterate_work_collegedialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="ยืนยันการบันทึกข้อมูล"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatetrate_work_collegeform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-card class="pa-4">
                            <div class="red--text font-weight-bold">
                              การยืนยันบันทึกข้อมูล
                              เมื่อกดยืนยันแล้วจะไม่สามารถแก้ไขข้อมูลใดๆ ได้แล้ว
                              กรุณาตรวจสอบข้อมูลให้ถูกต้องการการยืนยัน
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="updaterate_work_collegedialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updaterate_work_collegesSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon
                >&nbsp;ยืนยันการบันทึกข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <v-card-text>
            {{ snackbar.text }}
          </v-card-text>

          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar.show = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      user: {},
      users: [],
      select: { state: "2565", abbr: "2022" },
      updaterate_work_collegedialog: false,
      items: [
        { state: "2565", abbr: "2022" },
        { state: "2566", abbr: "2023" },
        { state: "2567", abbr: "2024" },
        { state: "2568", abbr: "2025" },
        { state: "2569", abbr: "2026" },
        { state: "2570", abbr: "2027" }
      ],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      rate_work_g_query: [],
      period_colleges: [],

      rate_work_colleges: {},
      rate_work_college_cal_rooms: {},
      rate_work_gs: {},
      rate_work_scs: {},
      rate_work_g_query_cal_idbranchs: [],
      sumstd: [],
      loading: true,
      dialogLoading: true,
      interval: {},
      value: 0,
      sys_config_collegemanpowers: [],
      rate_work_college_cal_roomCheck: [],
      rate_work_gCheck: []
    };
  },

  async mounted() {
    await this.sweetAlertLoading()
    let result;
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http.post("user.php", {
      ApiKey: this.ApiKey,
      user_ID: userSession.user_ID
    });
    this.user = result.data;  
    await this.rate_work_gQueryAll();
    await this.rate_work_g_cal_idbranchQueryAll();
    await this.rate_work_college_cal_roomQueryCheck();
    await this.rate_work_gCheckQrury();   
    Swal.close();

  },


  methods: {
    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();         
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async rate_work_gCheckQrury() {
      let result = await this.$http.post("rate_work_g.php", {
        ApiKey: this.ApiKey,
        rate_work_g_college_code: this.user.user_code,
        rate_work_g_year: this.select.state
      });
      this.rate_work_gCheck = result.data;
    },

    async rate_work_college_cal_roomQueryCheck() {
      let result = await this.$http.post("rate_work_college_cal_room.php", {
        ApiKey: this.ApiKey,
        rate_work_ccr_code: this.user.user_code,
        rate_work_ccr_year: this.select.state,
        rate_work_ccr_check: "OK"
      });
      this.rate_work_college_cal_roomCheck = result.data;
    },

   /*  async sys_config_collegemanpowerQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sys_config_collegemanpower.php", {
          ApiKey: this.ApiKey,
          sys_config_CollegemanpowerID: "1"
        })
        .finally(() => (this.loading = false));
      this.sys_config_collegemanpowers = result.data;
      if (
        this.sys_config_collegemanpowers.sys_config_CollegemanpowerHuman != "1"
      )
        this.$router.push("/college/rate_workforce_report");
    }, */

    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "update_college"
      });
      this.period_colleges = result_period_college.data;
    
    },

    async rate_work_gQueryAll() {
      let result = await this.$http
        .post("rate_work_g.php", {
          ApiKey: this.ApiKey,
          rate_work_g_college_code: this.user.user_code,
          rate_work_g_year:this.select.state
        })
      this.rate_work_g_query = result.data;    
    },

    async rate_work_g_cal_idbranchQueryAll() {
      let result = await this.$http.post("rate_work_college_cal_idbranch.php", {
        ApiKey: this.ApiKey,
        rate_work_college_code: this.user.user_code,
        rate_work_college_year: this.select.state
      });
      this.rate_work_g_query_cal_idbranchs = result.data;
      
    },

    async rate_work_g_search() {
      await this.sweetAlertLoading()
      this.loading = true;
      let result = await this.$http
        .post("rate_work_g.php", {
          ApiKey: this.ApiKey,
          rate_work_g_college_code: this.user.user_code,
          rate_work_g_year: this.select.state
        })
        .finally(() => (this.loading = false));
      this.rate_work_g_query = result.data;
      await this.rate_work_g_cal_idbranchQueryAll()
      Swal.close();
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    year_now() {
      let today = new Date();
      let yyyy = today.getFullYear() + 543;
      today = yyyy;
      return today;
    },
    color() {
      return "teal darken-4";
    }
  }
};
</script>
<style scoped>
.bottom-navi {
  font-size: 12pt;
}
.td-bg{
  background-color: #edf1a3;
}
.td-bg-sum{
  background-color: #badde8;
}
</style>
